/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  > .btn {
    @extend .mt-3;
    @extend .mr-3;
  }

  > .btn-toolbar {
    @extend .mt-3;
    @extend .mr-3;
  }

  > .btn-group,
  >  .btn-group-vertical {
    @extend .mt-2;
    @extend .mr-2;
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  > .dropdown {
    display: inline-block;
    @extend .mr-2;
    margin-bottom: 0.5rem;
  }

  nav {
    .breadcrumb {
      margin-bottom: 1.375rem;
    }

    &:last-child {
      .breadcrumb {
        margin-bottom: 0;
      }
    }
  }
}

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px;
}

.rounded-legend {
  ul {
    li {
      list-style-type: none;
      color: $text-muted;
      font-size: .75rem;
      .legend-dots {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .5rem;
        .rtl & {
          margin-left: .5rem;
        }
      }
    }
  }
  &.legend-horizontal {
    @include display-flex;
    ul {
      li {
        display: inline-block;
        margin-right: 1.5rem;
        .rtl & {
          margin-right: auto;
          margin-left: 1.5rem;
        }
      }
    }
  }
  &.legend-top-right {
    ul {
      float: right;
      .rtl & {
        float: left;
      }
    }
  }
  &.legend-vertical {
    ul {
      li {
        margin-top: 1rem;
      }
    }
  }
}
.purchase-popup {
  @extend .grid-margin;
  background: rgba(191, 187, 187, 0.46);
  padding: 15px 20px;
  @include border-radius(3px);

  .btn {
    margin-right: 20px;
    font-weight: 500;
    color: $white;
    @include border-radius(5px);
    @include transition-duration(0.2s);

    &.download-button {
      background: rgba(249, 249, 249, 0.7);
      color: #969292;
      border: 1px solid darken(#e4e4e4,10%);
    }

    &.purchase-button {
      background-color: rgba(172, 50 ,228, 0.9);
      @include background-image(theme-gradient-color(primary));
      color: $white;
      border: none;
      line-height: 1;
      vertical-align: middle;
    }
  }

  p {
    margin-bottom: auto;
    margin-top: auto;
    color: darken(#e4e4e4,40%);
    font-weight: 400;
    vertical-align: middle;
    line-height: 1;
  }

  i {
    vertical-align: middle;
    line-height: 1;
    margin: auto 0;
    color: darken(#e4e4e4,20%);
  }
}