/* Lists */

ul,
ol,
dl {
  padding-left: 1rem;
  font-size: $default-font-size;
  li {
    line-height: 1.8;
  }
}

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.5rem;
    &:before {
      font-family: "Material Design Icons";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem;
    }
  }
}

.list-ticked {
  li {
    &:before {
      content: '\F12D';
      color: theme-color(danger);
    }
  }
}

.list-arrow {
  li {
    &:before {
      content: '\F142';
      color: theme-color(success);
    }
  }
}

.list-star {
  li {
    &:before {
      content: '\F4CE';
      color: theme-color(warning);
    }
  }
}

.gradient-bullet-list {
  padding-left: 0;
  .rtl & {
    padding-right: 0;
  }
  li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 25px;
    &:before,
    &:after {
      content:"";
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%;
    }
    &:after {
      width: 11px;
      height: 11px;
      top: 2px;
      left: 2px;
      background: $white;
      border-radius: 100%;
    }
    &:nth-child(1) {
      &:before {
        background: theme-gradient-color(danger);        
      }
    }
    &:nth-child(2) {
      &:before {
        background: theme-gradient-color(info);        
      }
    }
    &:nth-child(3) {
      &:before {
        background: theme-gradient-color(warning);        
      }
    }
    &:nth-child(4) {
      &:before {
        background: theme-gradient-color(success);        
      }
    }
    &:nth-child(5) {
      &:before {
        background: theme-gradient-color(primary);        
      }
    }
  }
}